import HttpClient from './HttpClient';

class ApiClient extends HttpClient {
  constructor(baseURL = process.env.REACT_APP_API_BASE_URL, langCode = 'sv') {
    // console.log('process.env.REACT_APP_API_BASE_URL', process.env.REACT_APP_API_BASE_URL);
    super({
      baseURL,
      headers: {
        lang: langCode,
        accept: 'application/json',
        'Content-Type': 'application/json'
        // 'Access-Control-Allow-Credentials': true,
        // credentials: 'include'
      }
    });
  }

  users = {
    get: () => this.get('/users'),
    create: (user) => this.post('/users', user)
  };

  countdown = {
    get: () => this.get('/countdown')
  };

  winners = {
    get: (param) => this.get(`/winners${param ? `?${param}=1` : ''}`)
  };

  prizes = {
    get: () => this.get('/prizes')
  };
}

export default ApiClient;
