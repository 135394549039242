import { Col, Row } from 'react-bootstrap';
import Section from './Sections';

function YourChancesIncrease() {
  return (
    <Section color="purple">
      <Row>
        <Col>
          <h3>Ingen vinst? Vi ökar dina chanser till nästa dragning</h3>
          <p className="text-white">
            Om du inte vinner på ditt golf-id första gången du är med så dubblerar vi dina chanser att vinna vid nästa
            dragning. För varje vecka som du inte vinner så lägger vi till ditt golf-id en extra gång i tombolan. T.ex.
            om du inte vinner på 5 veckor så har du 5 extra chanser att vinna. Om du sedan vinner så börjar vi om från
            början.
          </p>
        </Col>
      </Row>
    </Section>
  );
}

export default YourChancesIncrease;
