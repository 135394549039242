import React, { useEffect, useState } from 'react';
import { Alert, Spinner, Table } from 'react-bootstrap';
import ApiClient from '../utils/ApiClient';
import Section from './Sections';

const client = new ApiClient();

function Winners() {
  const [winners, setWinners] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [apiError, setApiError] = useState(null);

  useEffect(() => {
    const fetchWinners = async () => {
      const { data: _winners, error } = await client.winners.get();
      if (error) {
        setApiError(error.message);
      } else {
        setWinners(_winners);
      }

      setIsLoading(false);
    };

    fetchWinners();
  }, []);

  return (
    <Section>
      <h3>Vi har vunnit under 2024</h3>
      {apiError && <Alert variant="danger">{apiError}</Alert>}
      <Table>
        <thead>
          <tr>
            <th>Vecka</th>
            <th>Vinnare</th>
            <th>Pris</th>
          </tr>
        </thead>
        <tbody>
          {winners?.map((winner) => (
            <tr key={winner.id}>
              <td>Vecka {winner.winnerWeek}</td>
              <td>{winner.user.name}</td>
              <td>{winner.prize?.product}</td>
            </tr>
          ))}
        </tbody>
      </Table>
      {isLoading && <Spinner />}
    </Section>
  );
}

export default Winners;
