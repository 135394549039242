class HttpClient {
  constructor(options = {}) {
    this._baseURL = options.baseURL || '';
    this._headers = options.headers || {};
  }

  async _fetchJSON(endpoint, options = {}) {
    // const { tokenData } = useStore.getState();
    // console.log('tokenData', tokenData?.token.plainTextToken);
    // const { setTokenData } = useStore.getState();
    // const { setAuthenticate } = useStore.getState();
    // this.setBearerAuth(tokenData?.token.plainTextToken);

    // const isAdminRequest = window.location.pathname?.includes('backend');
    // if (isAdminRequest) {
    //   // eslint-disable-next-line no-param-reassign
    //   endpoint += `${endpoint.includes('?') ? '&' : '?'}admin-request=1`;
    // }

    let res;
    try {
      res = await fetch(this._baseURL + endpoint, {
        ...options,
        headers: this._headers
      });
    } catch (err) {
      // console.log('err', err);
      // return Promise.reject(err.message || err);
      return { error: err.message || err };
    }

    if (!res.ok) {
      // if (res.status === 401) {
      //   // setTokenData(null);
      // }
      // return { error: (await MyHttpClient.parseResponseBody(res)) || res.statusText };
      const error = (await HttpClient.parseResponseBody(res, true)) || res.statusText;
      // return Promise.reject(errorMessage);
      return error;
    }

    if (options.parseResponse !== false && res.status !== 204) {
      return HttpClient.parseResponseBody(res);
    }

    return undefined;
  }

  setHeader(key, value) {
    this._headers[key] = value;
    return this;
  }

  getHeader(key) {
    return this._headers[key];
  }

  setBasicAuth(username, password) {
    this._headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
    return this;
  }

  setBearerAuth(token) {
    this._headers.Authorization = `Bearer ${token}`;
    return this;
  }

  get(endpoint, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      method: 'GET'
    });
  }

  post(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: body ? JSON.stringify(body) : undefined,
      method: 'POST'
    });
  }

  put(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      ...options,
      body: body ? JSON.stringify(body) : undefined,
      method: 'PUT'
    });
  }

  patch(endpoint, operations, options = {}) {
    return this._fetchJSON(endpoint, {
      parseResponse: false,
      ...options,
      body: JSON.stringify(operations),
      method: 'PATCH'
    });
  }

  delete(endpoint, body, options = {}) {
    return this._fetchJSON(endpoint, {
      parseResponse: false,
      ...options,
      body: body ? JSON.stringify(body) : undefined,
      method: 'DELETE'
    });
  }

  static async parseResponseBody(response, isError = false) {
    try {
      const key = isError ? 'error' : 'data';
      const body = await response.json();
      // console.log('parseResponseBody', key, body);
      return { [key]: body };
    } catch {
      return null;
    }
  }
}

export default HttpClient;
