import React, { useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import useCountDown from 'react-countdown-hook';
import ApiClient from '../utils/ApiClient';
import usePageVisibility from '../utils/hooks/usePageVisibility';
import Section from './Sections';

const client = new ApiClient();

const oneMinute = 60000;

function convertMiliseconds(miliseconds) {
  const totalSeconds = parseInt(Math.floor(miliseconds / 1000), 10);
  const totalMinutes = parseInt(Math.floor(totalSeconds / 60), 10);
  const totalHours = parseInt(Math.floor(totalMinutes / 60), 10);
  const days = parseInt(Math.floor(totalHours / 24), 10);

  const seconds = parseInt(totalSeconds % 60, 10);
  const minutes = parseInt(totalMinutes % 60, 10);
  const hours = parseInt(totalHours % 24, 10);

  return { d: days, h: hours, m: minutes, s: seconds };
}

function CountdownTimer() {
  const [lastWinner, setLastWinner] = useState(null);

  const lastSyncTimeRef = useRef(null);

  const [timeLeft, timer] = useCountDown(null, 1000);
  const isPageVisible = usePageVisibility();

  useEffect(() => {
    syncWithServer();
    const intervalId = setInterval(syncWithServer, 60000 * 5);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      syncWithServer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPageVisible]);

  const syncWithServer = async () => {
    const now = Date.now();

    // Check if one minute has passed
    if (now - lastSyncTimeRef.current >= oneMinute) {
      lastSyncTimeRef.current = now; // Update the last execution time

      const { data } = await client.countdown.get();
      const { nextDrawIn, lastWinner: _lastWinner } = data;
      timer.start(nextDrawIn);
      setLastWinner(_lastWinner);
    }
  };

  const waitingForWinner = timeLeft === 0;

  const { d, h, m, s } = convertMiliseconds(timeLeft);

  return (
    <Section color="orange">
      <h3>Vi drar en ny vinnare om:</h3>
      {waitingForWinner && <WaitingForWinner />}

      {!waitingForWinner && (
        <div>
          {timeLeft === 0 ? (
            <Spinner variant="light" />
          ) : (
            <>
              <h3 className="text-white" id="time-left">
                {d > 0 ? `${d} d ` : ''} {h} h {m} min {s} sek
              </h3>
              {lastWinner && (
                <p className="text-white">
                  Senaste vinnaren: {lastWinner.user.name}, vecka {lastWinner.winnerWeek}
                </p>
              )}
            </>
          )}
        </div>
      )}
    </Section>
  );
}

function WaitingForWinner() {
  const [winner, setWinner] = useState(null);

  const intervalId = useRef(null);

  useEffect(() => {
    const syncWithServer = async () => {
      const { data: _winner } = await client.winners.get('today');
      setWinner(_winner);

      if (_winner?.user) {
        clearInterval(intervalId.current);
      }
    };

    syncWithServer();
    intervalId.current = setInterval(syncWithServer, 5000);

    return () => clearInterval(intervalId.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return winner?.user ? (
    <p>
      Grattis {winner.user.name}, du är vår vinnare vecka {winner.winnerWeek}
    </p>
  ) : (
    <div>
      <Spinner size="sm" variant="light" />
      <p className="text-white">Väntar på en vinnare</p>
    </div>
  );
}

export default CountdownTimer;
