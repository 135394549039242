import { Col, Row } from 'react-bootstrap';

function Section({ color, className, children }) {
  return (
    <Row className={`d-flex flex-column p-5 pb-4 section ${color} ${className}`}>
      <Col className="d-flex flex-column justify-content-center align-items-center py-sm-4">
        <div className="section-content">{children}</div>
      </Col>
    </Row>
  );
}

export default Section;
