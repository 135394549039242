import { Button } from 'react-bootstrap';
import Section from './Sections';

function HowItWorks({ showRegisterView }) {
  return (
    <Section color="green" className="how-it-works">
      <h3 className="text-sm-start">Så här funkar Svenska Golf-id lotteriet</h3>
      <p className="mt-3 text-white text-sm-start">
        <span>
          Registrera ditt{' '}
          <Button variant="link" onClick={showRegisterView}>
            golf-id här &raquo;
          </Button>
        </span>
        <span>Varje onsdag kl 20.00 drar vi en vinnare från den elektroniska tombolan</span>
        <span>Vinner du så kommer vi kontakta dig via e-post</span>
        <span>Om du inte har vunnit ser vi till att öka dina vinstchanser till nästa vecka</span>
      </p>
    </Section>
  );
}

export default HowItWorks;
