import { useState } from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import ApiClient from '../utils/ApiClient';

const golfIdPattern = /^\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])-\d{3}$/;
const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

const client = new ApiClient();

function RegisterForm({ onClose }) {
  const [isLoading, setIsLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [user, setUser] = useState(null);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setApiError(null);
    setIsLoading(true);
    setUser(null);

    const { error, data: _user } = await client.users.create(data);
    if (error) {
      setApiError(error.message);
    } else {
      setUser(_user);
    }

    setIsLoading(false);
  };

  const formatGolfId = ({ target }) => {
    let golfId = target.value;
    const golfIdHasDash = golfId.includes('-');
    const golfIdLength = golfId.length;
    golfId = !golfIdHasDash && golfIdLength === 9 ? `${golfId.slice(0, 6)}-${golfId.slice(6, 9)}` : golfId;
    setValue('golfId', golfId, { shouldValidate: true });
  };

  return (
    <Modal show onHide={onClose}>
      <Form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="pb-0" style={{ border: 0 }}>
          {/* <Modal.Title>Modal heading</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <p>
            Anmäl dig nedan för att deltaga i utlottningen. Notera att vi kontrollerar golf-id i efterhand på alla
            vinnare.
          </p>
          <Form.Group className="mb-3">
            <Form.Label>Golf-id</Form.Label>
            <Form.Control
              type="tel"
              {...register('golfId', {
                required: true,
                pattern: golfIdPattern,
                onBlur: formatGolfId
              })}
              isInvalid={errors.golfId}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>E-post</Form.Label>
            <Form.Control
              type="email"
              {...register('email', {
                required: true,
                pattern: emailPattern
              })}
              isInvalid={errors.email}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Name</Form.Label>
            <Form.Control
              {...register('name', {
                required: true,
                minLength: 5
              })}
              isInvalid={errors.name}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Hemmaklubb</Form.Label>
            <Form.Control
              {...register('club', {
                required: true,
                minLength: 3
              })}
              isInvalid={errors.club}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Check
              type="checkbox"
              // eslint-disable-next-line max-len
              label="Jag intygar att uppgifterna är korrekta och godkänner att de sparas och används för att administrera lotteriet."
              {...register('approved', {
                required: true
              })}
              isInvalid={errors.approved}
            />
          </Form.Group>
          {apiError && <Alert variant="danger">{apiError}</Alert>}
          {user && <Alert variant="success">Ditt golf-id {user.golfId} är registrerat.</Alert>}
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="primary" disabled={isLoading}>
            {isLoading && <Spinner size="sm" />} Registrera
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default RegisterForm;
