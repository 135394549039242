import { Col, Image, Row } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Section from './Sections';
import ApiClient from '../utils/ApiClient';

const client = new ApiClient();

function ThisWeeksPrice() {
  const [prize, setPrize] = useState(null);

  useEffect(() => {
    const fetchPrice = async () => {
      const { data } = await client.prizes.get();
      setPrize(data);
    };
    fetchPrice();
  }, []);

  return (
    <Section>
      {prize && (
        <Row>
          <Col sm="4" className="text-sm-end mb-3">
            <Image src={prize.image} fluid style={{ width: '200px' }} alt="Taylor Made Qi10" />
          </Col>
          <Col sm="8" className="d-flex flex-column justify-content-center">
            <h4 className="text-sm-start">Det här kan du vinna i kommande dragning</h4>
            <h6 className="text-sm-start text-italic">
              {prize.product} (Värde: {prize.value}.-)
            </h6>
            <p className="text-sm-start">{prize.description}</p>
          </Col>
        </Row>
      )}
    </Section>
  );
}

export default ThisWeeksPrice;
