import { useState } from 'react';
import { Button } from 'react-bootstrap';
import Section from './Sections';
import EnhancedAreYouAWinnerLottery from './AreYouAWinnerLottery';

const golfIds = [790620002, 790420009, 790406019, 870907033, 670128006];
const nextGolfId = (currentGolfId) => {
  const filteredGolfIds = golfIds.filter((id) => id !== currentGolfId);
  return filteredGolfIds[Math.floor(Math.random() * filteredGolfIds.length)];
};

function AreYouAWinner({ showRegisterView }) {
  const [number, setNumber] = useState(nextGolfId());

  return (
    <Section color="lgreen">
      <h3>Är det ditt golf-id som vinner?</h3>
      {/* <h2 className="text-white">790420-009</h2> */}

      <EnhancedAreYouAWinnerLottery
        includeDash
        onReady={() => setNumber(nextGolfId(number))}
        transitions={(index) => ({
          type: 'spring',
          duration: index + 0.3
        })}
        animateToNumber={number}
        fontStyle={{
          fontSize: 26,
          color: '#fff'
        }}
      />

      <p className="text-white">
        Är du inte med i lotteriet? Det är enkelt <br /> och kostnadsfritt{' '}
        <Button variant="link" onClick={showRegisterView}>
          att gå med &raquo;
        </Button>
      </p>
    </Section>
  );
}

export default AreYouAWinner;
