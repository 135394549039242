import { Button, Col, Row } from 'react-bootstrap';

function Header({ showRegisterView }) {
  return (
    <header className="header-image p-5">
      <Row className="d-flex flex-column h-100">
        <Col className="d-flex flex-column justify-content-center align-items-center">
          <h1 className="text-white">Lotteriet för golfare</h1>
          <h3 className="text-white">
            Enkelt och grattis
            <br /> att vara med
            <br />
            Vinstchans varje vecka
            <br />
            Fina vinster
          </h3>
          <Button variant="primary" size="lg" className="mt-4" onClick={showRegisterView}>
            Gå med i lotteriet &raquo;
          </Button>
        </Col>
      </Row>
    </header>
  );
}

export default Header;
