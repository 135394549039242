import { useState } from 'react';
import { Container } from 'react-bootstrap';
import CountdownTimer from './components/CountdownTimer';
import RegisterForm from './components/RegisterForm';
import Winners from './components/Winners';
import Header from './components/Header';
import HowItWorks from './components/HowItWorks';
import AreYouAWinner from './components/AreYouAWinner';
import ThisWeeksPrice from './components/ThisWeeksPrice';
import YourChancesIncrease from './components/YourChancesIncrease';
import Footer from './components/Footer';

// eslint-disable-next-line no-console
console.log('Version: ', process.env.REACT_APP_VERSION);

function App() {
  const [showModal, setShowModal] = useState(false);

  const showRegisterView = () => setShowModal(true);
  const closeRegisterView = () => setShowModal(false);

  return (
    <div className="App">
      <Container fluid className="p-0">
        <Header showRegisterView={showRegisterView} />
        <HowItWorks showRegisterView={showRegisterView} />
        <CountdownTimer />
        <AreYouAWinner showRegisterView={showRegisterView} />
        <ThisWeeksPrice />
        <YourChancesIncrease />
        <Winners />
        <Footer />
      </Container>
      {showModal && <RegisterForm onClose={closeRegisterView} show={showModal} />}
    </div>
  );
}

export default App;
