import React, { useCallback, useEffect, useState } from 'react';
import { motion, useAnimation, useInView } from 'framer-motion';

const NUMBERS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

let animationCompleted = 0;
const numbersLength = NUMBERS.length;

// lib
function AreYouAWinnerLottery({ animateToNumber, fontStyle, transitions, onReady }) {
  const ref = React.useRef(null);
  const isInView = useInView(ref, { once: false });

  const controls = useAnimation();
  const keyCount = React.useRef(0);
  const animateToNumberString = String(animateToNumber);
  const animateTonumberString = `${animateToNumberString.substring(0, 6)}-${animateToNumberString.substring(6)}`;

  const animateToNumbersArr = Array.from(animateTonumberString, Number).map((x, idx) =>
    Number.isNaN(x) ? animateTonumberString[idx] : x
  );

  const [numberHeight, setNumberHeight] = useState(0);
  const [numberWidth, setNumberWidth] = useState(0);

  const numberDivRef = React.useRef(null);

  useEffect(() => {
    const rect = numberDivRef.current.getClientRects()?.[0];
    if (rect) {
      setNumberHeight(rect.height);
      setNumberWidth(rect.width);
    }
  }, []);

  useEffect(() => {
    if (isInView && animateToNumber) {
      controls.start('visible');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInView, animateToNumber]);

  // useEffect(() => {
  //   if (isInView) {
  //     controls.start('visible');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [isInView]);

  const handleOnAnimationComplete = useCallback(() => {
    animationCompleted += 1;
    if (animationCompleted >= numbersLength * animateToNumberString.length) {
      animationCompleted = 0;
      controls.stop();
      restartSoon();
    }
  }, []);

  const restartSoon = () => {
    setTimeout(() => {
      keyCount.current = 0;
      controls.set({
        x: 0,
        y: 0
      });
      onReady();
    }, 1000);
  };

  return (
    <span ref={ref}>
      {numberHeight !== 0 && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            overflow: 'hidden'
          }}
          className="justify-content-center"
        >
          {animateToNumbersArr.map((n, index) => {
            if (typeof n === 'string') {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  style={{ ...fontStyle, fontVariantNumeric: 'tabular-nums' }}
                >
                  {n}
                </div>
              );
            }

            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} style={{ height: numberHeight, width: numberWidth }}>
                {NUMBERS.map((number) => (
                  <motion.div
                    style={{ ...fontStyle, fontVariantNumeric: 'tabular-nums' }}
                    // eslint-disable-next-line no-plusplus
                    key={`${keyCount.current++}`}
                    initial="hidden"
                    variants={{
                      hidden: { y: 0 },
                      visible: {
                        y: -1 * (numberHeight * animateToNumbersArr[index]) - numberHeight * 20
                      }
                    }}
                    animate={controls}
                    transition={transitions?.(index)}
                    onAnimationComplete={handleOnAnimationComplete}
                  >
                    {number}
                  </motion.div>
                ))}
              </div>
            );
          })}
        </div>
      )}

      <div ref={numberDivRef} style={{ position: 'absolute', top: -9999, ...fontStyle }}>
        {0}
      </div>
    </span>
  );
}

const EnhancedAreYouAWinnerLottery = React.memo(AreYouAWinnerLottery, (prevProps, nextProps) => {
  return (
    prevProps.animateToNumber === nextProps.animateToNumber &&
    prevProps.fontStyle === nextProps.fontStyle &&
    prevProps.includeComma === nextProps.includeComma
  );
});

export default EnhancedAreYouAWinnerLottery;
